import React, { useEffect, useRef, useState } from "react";
import Icon from "../../../../../components/Icon/Icon";
import Button from "../../../../../components/Buttons/Button";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomerInfoList,
  sendMemberReminders,
  setCustomerUpdateStatus,
  updateCustomerInfo,
  updateMemberSubscriptionInfo,
  setMemberDetailsVisible,
  fetchCountries,
  fetchCities,
} from "../../../mosqueAction";
import dayjs from "dayjs";
import { MonthData } from "../../../../../constants/AdminData";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { getFormattedTime } from "../../../../../util/timeUtility";
import styled from "styled-components";
import { isMonthUpcoming, showNotification } from "../../../../../util/Utility";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DisableMemberModal from "./DisableMemberModal";
import truncate from "lodash/truncate";
import moment from "moment";
import EditForm from "./EditForm";
import { background, bottom, color, fontWeight, left, marginBottom, position, right, width } from "styled-system";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import UPDATE_MEMBER_INFO_STATUS_SUCCESS from 
import { UPDATE_MEMBER_INFO_STATUS_SUCCESS } from "../../../mosqueActionType";

const NextPaymentDateText = styled.span`
font-family: Raleway;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #fe1717;

`

const NextPaymentDateWrapper = styled.div`
width: fit-content;
  height: 28px;
  padding: 6px 12px;
  border-radius: 4px;
  background: #ffd5d5;
  margin: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;
const SelectBoxWrapper = styled.span`
  .ant-select-selection-item {
    font-size: 18px;
    font-weight: 600;
    font-family: Raleway;
  }
`;
const ProfileModal = ({ onHide ,refetchCustomerList}) => {
  const dispatch = useDispatch();
  const [isActiveEdit, setIsActiveEdit] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [activeMemberDetails, setActiveMemberDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [historyPageNum, setHistoryPageNum] = useState(1);
  const [historyPageSize, setHistoryPageSize] = useState(2);
  const isFetchingCities = useSelector(
    (state) => state.mosqueReducer.isFetchingCities
  );
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const agent = useSelector((state) => state.mosqueReducer.agent);
  const activeMember = useSelector((state) => state.mosqueReducer.activeMember);
  const [isFocusedIBANOne, setIsFocusedIBANOne] = useState(false);
  const [isFocusedIBANTwo, setIsFocusedIBANTwo] = useState(false);
  const [isFocusedCustName, setIsFocusedCustName] = useState(false);
  const [isDetailsUpdated,setIsDetailsUpdated] = useState(false);
  const customer = useSelector(
    (state) => state.mosqueReducer.memberDetails?.users
  );
  const isUpdatedSuccessfully = useSelector(
    (state) => state.mosqueReducer.memberDetails?.isUpdatedSuccessfully
  );
  const customerInfoPayments = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoPayments
  );
  const reminder_history = useSelector(
    (state) => state.mosqueReducer.memberDetails?.reminder_history
  );
  const payment_history = useSelector(
    (state) => state.mosqueReducer.memberDetails?.payment_history
  );
  const total_reminder_count = useSelector(
    (state) => state.mosqueReducer.memberDetails?.total_reminder_count
  );
  const is_sending_reminder = useSelector(
    (state) => state.mosqueReducer.is_sending_reminder
  );
  const memberPlans = useSelector((state) => state.mosqueReducer.memberPlans);
  const plan_list = memberPlans?.planList;

  const customerLists = useSelector(
    (state) => state.mosqueReducer.customerInfo?.customerInfoList
  );
  const [compareIban, setCompareIban] = useState('')
  const [isCompareIbanError,setIsCompareIbanError]= useState(false)
  const [showChangeSub, setShowChangeSub] = useState(false)
  const [isPaymentModeChanged,setIsPaymentModeChanged] = useState(false)
  const [isAccountNameChanged,setIsAccountNameChanged] = useState(false)
  const [isIbanChanged, setIsIbanChanged] = useState(false)
  const [comment, setComment] = useState('');
  const showLoadMoreButton = Math.ceil(total_reminder_count / historyPageSize);
  const userRef = useRef(null)
  const {t} = useTranslation()

  console.log('active memebr details',activeMemberDetails);
  const handleFocusOne = () => {
    setIsFocusedIBANOne(prev=>!prev);
};
const handleFocusTwo = () => {
  setIsFocusedIBANTwo(prev=>!prev);
};
const handleFocusCustName = ()=>{
  setIsFocusedCustName(prev=>!prev)
}
const handleBlurCustName = ()=>{
  setIsFocusedCustName(false)
}
// const handleBlur = () => {
//     setIsFocusedIBAN(false);
// }
  useEffect(() => {
    if (activeMember) {
      userRef.current = activeMember
      setActiveMemberDetails({ ...activeMember });
      setCompareIban(activeMember?.customerIBAN)
      // setIsDisable(activeMember.isDeactivated)
    }
  }, [activeMember]);
  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  useEffect(() => {
    if (activeEntity?._id) {
      dispatch(
        fetchCustomerInfoList({
          entity_id: activeEntity?._id,
          member_id: activeMember?._id,
          pageSize: 30,
          pageNum: 1,
        })
      );
    }
  }, [dispatch, activeEntity]);

  /*useEffect(() => {
        if (activeMember && activeMember._id) {
            dispatch(fetchMemberReminderHistory({
                member_id: activeMember.member_id,
                pageNum: historyPageNum,
                pageSize: historyPageSize
            }));
        }
    }, [])*/

  /*const loadMoreButton = (page) => {
        setHistoryPageNum(page);
        if (page && historyPageSize) {
            dispatch(fetchMemberReminderHistory({
                member_id: activeMember.member_id,
                pageNum: page,
                pageSize: historyPageSize
            }));
        }
    }*/

  const optionsPlan = () => {
    let listValues = [];
    console.log('plan lsit',plan_list);
    if (plan_list?.length) {
      plan_list.forEach((plan) => {
        if (plan.isFree) {
          // Check for a 'isFree' property in the plan object
          // Don't push the free plan to listValues
          return;
        }
        listValues.push({
          ...plan,
          label: `${plan.plan_name} - € ${plan.amount}`,
          value: plan._id,
        });
      });
    }
    listValues.unshift({
      label: t("Free Subscription"),
      value: null, //
    });
    return listValues;
  };
 const getPaymentMethods =()=>{
   let methods = [{
    label:t('Auto Pay'),
    value:'automatic'
   },{
    label:t('Free Subscription'),
    value:null
   }]

   return methods;
 }
 console.log('activeMemverDetaiks', activeMemberDetails);
  const renderPaymentStatus = (item, month) => {
    const filteredData = item?.payments?.filter(
      (payment) => payment.month === month
    );
    if (filteredData?.length > 0) {
      return filteredData.map((payment) => {
        let color = "";
        switch (payment.status) {
          case "FAILED":
          case "MISSED":
          case false:
            color = "red-color";
            break;
          case "PENDING":
          case "PROCESSING":
            color = "orange-color";
            break;
          case "SUCCESS":
          case true:
            color = "green-color";
            break;
        }
        return (
          <div key={month} style={{ display: "flex" }}>
            {filteredData.map((payment) => (
              <Box
                key={payment._id}
                className={color}
                top="5px"
                left="5px"
                position="relative"
              />
            ))}
          </div>
        );
      });
    } else {
      return <Box ml="7px">{"-"}</Box>;
    }
  };

  const disableMember = () => {
    // if (activeMemberDetails?.subscriptions?.[0]?.status === "ACTIVE") {
    //   setIsDisable((prevState) => !prevState);
    // }
    setIsDisable((prevState) => !prevState);
    // if (!activeMemberDetails?.isDeactivated) {
      
    // }

  };

  const updateStatus = () => {
    const update = {};
    update._id =
    activeMemberDetails?._id;
    update.entityId = activeMemberDetails?.subscriptions?.[0]?.entityId;
    update.comment = comment;
    update.agentId = agent._id;
    if (!activeMemberDetails?.isDeactivated) {
      update.status = "INACTIVE";
      dispatch(
        updateMemberSubscriptionInfo(activeMemberDetails?._id, update, true, refetchCustomerList)
      ).then(() => {
        // Redirect after successful submission
        window.location.href = `/mosque/${activeEntity?._id}/info`;
      });
    } else if (activeMemberDetails?.isDeactivated) {
      update.status = "ACTIVE";
      dispatch(
        updateMemberSubscriptionInfo(activeMemberDetails?._id, update, true, refetchCustomerList)
      ).then(() => {
        // Redirect after successful submission
        window.location.href = `/mosque/${activeEntity?._id}/info`;
      });
    }
    setIsDisable(false);
  };

  useEffect(() => {
    // console.log(activeMemberDetails?.subscription_plan_id, optionsPlan()[0].value);
    if (!activeMemberDetails?.subscriptions?.[0]?.subscriptionId) {
      const subs = activeMember?.subscriptions.find((sub)=> sub.status === 'ACTIVE');
      setActiveMemberDetails((prevDetails) => ({
        ...prevDetails,
        subscription_plan_id: subs?.subscriptionId,
      }));
    }
  }, [activeMemberDetails?.subscriptions?.[0]?.subscriptionId]);

  const handleUpdateDetails = (keyName, keyValue) => {
    console.log(keyName,keyValue);
    setIsDetailsUpdated(true);
    if(keyName === 'customerAccountName')
      setIsAccountNameChanged(true)
    if(keyName === 'customerIBAN')
      setIsIbanChanged(true)
    setErrors({});
    setIsNext(true);
    if (isActiveEdit) {
      if (keyName === "contact_name") {
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          name: keyValue,
        }));
      } else if (keyName === "contact_phone") {
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          phoneNumber: keyValue,
        }));
      } else if (keyName === "country_code") {
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          countryCode: keyValue,
        }));
      } else if (keyName === "contact_email") {
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          emailAddress: keyValue,
        }));
      } else if (keyName === "country") {
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          country: keyValue,
          city: "",
        }));
      } else if (keyName === "subscription_plan_id") {
        console.log('change', keyValue);
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          subscription_plan_id: keyValue,
        }));
      } else if( keyName === "paymentMode"){
        setIsPaymentModeChanged(true)
        if(keyValue === null){
          setActiveMemberDetails((prevDetails) => ({
            ...prevDetails,
            paymentMode:keyValue
  
          }));
        } else if( keyName === "customerIBAN"){
          setIsIbanChanged(true);
          setActiveMemberDetails((prevDetails) => ({
            ...prevDetails,
           customerIBAN: keyValue
          }));
        }
        else if( keyName === "customerAccountName"){
          setIsAccountNameChanged(true);
          setActiveMemberDetails((prevDetails) => ({
            ...prevDetails,
           customerAccountName: keyValue
          }));
        }
        else{
          setActiveMemberDetails((prevDetails) => ({
            ...prevDetails,
            paymentMode: keyValue
          }));
        }
      } 
      else {
        // Otherwise, update the field normally
        setActiveMemberDetails((prevDetails) => ({
          ...prevDetails,
          [keyName]: keyValue,
        }));
      }
    }
  };
// console.log('subscription_plan_id', activeMemberDetails?.subscription_plan_id);
  const showMemberDetails = (item, val = true) => {
    dispatch(setMemberDetailsVisible(item, val));
    window.location.href = `/mosque/${activeEntity?._id}/members/${activeMember?._id}/edit`;
  };

  /*const handleChange = (keyName, keyValue) => {
        setErrors({});
        setIsNext(true);
        const data = {...activeMemberDetails};
        data[keyName] = keyValue;
        setActiveMemberDetails(data);
    }*/

  const emailRegex = /^\S+@\S+\.\S+$/;

  const handleValidation = () => {
    let isValid = true;
    let errors = {};
    console.log("INSIDE VALIDAITION");
    console.log(activeMemberDetails);

    // Validation for name
    if (!activeMemberDetails?.name || activeMemberDetails?.name.trim() === "") {
      isValid = false;
      errors.name = "Name is required";
    }

    // Validation for emailAddress
    if (!emailRegex.test(activeMemberDetails?.emailAddress)) {
      isValid = false;
      errors.emailAddress = "Invalid email address";
    }

    // Validation for countryCode
    if (
      !activeMemberDetails?.countryCode ||
      activeMemberDetails?.countryCode.trim() === ""
    ) {
      isValid = false;
      errors.countryCode = "Country code is required";
    }

    // Validation for phoneNumber
    if (
      !activeMemberDetails?.phoneNumber ||
      activeMemberDetails?.phoneNumber.trim() === ""
    ) {
      isValid = false;
      errors.phoneNumber = "Phone number is required";
    }

    // Validation for country
    if (
      !activeMemberDetails?.country ||
      activeMemberDetails?.country.trim() === ""
    ) {
      isValid = false;
      errors.country = "Country is required";
    }

    // Validation for contact_city
    if (!activeMemberDetails?.city || activeMemberDetails?.city.trim() === "") {
      isValid = false;
      errors.city = "City is required";
    }

    // Validation for subscription_plan_id
    // if (
    //   !activeMemberDetails?.subscription_plan_id ||
    //   activeMemberDetails?.subscription_plan_id.trim() === ""
    // ) {
    //   isValid = false;
    //   errors.subscription_plan_id = "Subscription plan ID is required";
    // }
    return {
      isValid,
      errors,
    };
  };

  const handleSubmit = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {
      setIsActiveEdit(false);
      if (activeMemberDetails) {
        activeMemberDetails.entityId = activeMemberDetails?.subscriptions?.[0]?.entityId || activeEntity._id;
        console.log('send payload', activeMemberDetails);
        dispatch(
          updateMemberSubscriptionInfo(
            activeMemberDetails?._id,
            activeMemberDetails,false,refetchCustomerList
          )
        );
      }
    } else {
      setErrors(validate.errors);
    }
  };
  console.log("this user details : ", activeMemberDetails);

  const sendReminder = async () => {
    let members = [];
    members.push(activeMember?._id);
    if (members.length > 0) {
      dispatch(
        sendMemberReminders({
          entity_id: activeEntity?._id,
          activeMember: activeMember,
          members,
          isProfileModal: true,
          pageNum: historyPageNum,
          pageSize: historyPageSize,
        })
      );
    } else {
      showNotification("error", "Please Select Users");
    }
  };

  const getIfCurrentPaymentIsPending = (payment_history) => {
    if (payment_history && payment_history.length > 0) {
      let start_of_month = moment().startOf("month");
      let end_of_month = moment().endOf("month");
      let filtered_data = payment_history.filter(
        (item) =>
          item.payment_status !== "paid" &&
          moment(new Date(item.payment_due_date)).isBetween(
            start_of_month,
            end_of_month
          )
      );
      if (filtered_data && filtered_data.length) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const shouldDisableSubChangeBtn = ()=>{
    if(activeMemberDetails?.paymentMode==='automatic'){
      if( !activeMemberDetails?.customerAccountName || !activeMemberDetails?.customerIBAN)
        return true;
    }
    return false;
  }

 const getNextPaymentDate = () =>{
   const date = new Date();
   const day = date.getDate();

   if(day>1)
    {
      const month = date.getMonth()+2;
      if(month<9)
      return `01/0${month}/${date.getFullYear()}`
      else
      return `01/${month}/${date.getFullYear()}`
    }
   else
   {
    const month = date.getMonth()+1;
    if(month<9)
    return `01/0${month}/${date.getFullYear()}`
    else
    return `01/${month}/${date.getFullYear()}`
  }
 }
  const formattedMembershipDate =
    activeMemberDetails?.subscriptions &&
    activeMemberDetails.subscriptions.length > 0
      ? getFormattedTime(
          activeMemberDetails.subscriptions[0].date,
          "DD MMM YYYY, hh:mm a"
        )
      : "-";
  console.log("customer name : ", activeMemberDetails?.customerAccountName);
  console.log("customer IBAN : ", activeMemberDetails?.customerIBAN);
  const getSelectedPlan =(id)=>{
    return plan_list.find((plan)=> plan._id===id)
  }

  const handleChangeSubscription = async ()=>{
    console.log(activeMemberDetails?.paymentMode, activeMemberDetails);
    let data ={}
    if(activeMemberDetails?.paymentMode !== userRef?.current.paymentMode){
      if(activeMemberDetails?.paymentMode==='automatic'){
         data.entityId = activeMemberDetails?.subscriptions[0]?.entityId
         data.subscriptionId = activeMemberDetails?.subscription_plan_id
         data.customerIBAN= activeMemberDetails?.customerIBAN
         data.customerAccountName = activeMemberDetails?.customerAccountName
         data.paymentMode = activeMemberDetails?.paymentMode
      }
      else{
        data.paymentMode = null
        data.entityId = activeMemberDetails?.subscriptions[0]?.entityId
      }
    }
    else{
      if(activeMemberDetails?.paymentMode==='automatic'){
        data.customerAccountName = activeMemberDetails?.customerAccountName
        data.customerIBAN = activeMemberDetails?.customerIBAN
        data.entityId = activeMemberDetails?.subscriptions[0]?.entityId
        data.subscriptionId = activeMemberDetails?.subscription_plan_id
        data.paymentMode = activeMemberDetails?.paymentMode
      }
      else{
         return;
      }
    }

    try {
      if(activeMemberDetails?.paymentMode==='automatic' && (!data?.customerAccountName || !data?.customerIBAN)) return;
      if(activeMemberDetails?.paymentMode==='automatic' && activeMemberDetails?.customerIBAN !== compareIban){
        setIsCompareIbanError(true);
        return;
      }
      const baseUrl = process.env.REACT_APP_STAGE==='development'?'apidev.mosqapp.com':'api.mosqapp.com'
      const res = await fetch(`https://${baseUrl}/member/${activeMemberDetails?._id}/modify-subscription`, { 
        method: "POST",  
        body: JSON.stringify(data), 
        headers: { 
            "Content-type": "application/json; charset=UTF-8"
        } 
    })
    const json  = await res.json()
    console.log('data-->', data)
    console.log('after ->',activeMemberDetails);
    console.log('json-->',json.data);
    refetchCustomerList();
        dispatch({
          type: UPDATE_MEMBER_INFO_STATUS_SUCCESS,
          details: json?.data,
          isFetching: false,
          isDisable: isDisable,
        })
    window.location.reload() ;
    
    } catch (error) {
      console.log(error);
    }
  }
console.log('active memever details --------------___>', activeMemberDetails);

console.log('shoudl Disable', shouldDisableSubChangeBtn());
  return (
    <Box>
      <Box style={{ margin: "0px 20px", top: "20px", position: "relative" }}>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-baseline">
              <h4
                style={{ cursor: "pointer" }}
                title={activeMemberDetails?.name || "NA"}
                onMouseOver={(e) =>
                  (e.target.title = activeMemberDetails?.name)
                }
                onMouseOut={(e) =>
                  (e.target.title = activeMemberDetails?.name
                    ? truncate(activeMemberDetails?.name, { length: 20 })
                    : "NA")
                }
              >
                {activeMemberDetails?.name
                  ? truncate(activeMemberDetails?.name, { length: 20 })
                  : "NA"}
              </h4>
              <div>
                <label
                  className="switch"
                  title={
                    activeMemberDetails?.subscriptions?.[0]?.status
                      ? "Disable Member"
                      : "Enable Member"
                  }
                >
                  <input
                    type="checkbox"
                    onChange={() => disableMember()}
                    placeholder={t("disable member")}
                    checked={!activeMemberDetails?.isDeactivated}
                    //disabled={!isActiveEdit}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <Label style={{marginLeft:'15px'}}>{(activeMemberDetails?.isDeactivated)?t('Enable user'):t('Disable user')}</Label>
              {activeMemberDetails?.isMobileOnBoarded ? null : (
                <div className="" style={{ maxWidth: "80%"}}>
                  {/* <Button
                    bg={isActiveEdit ? "#CCCCCC" : "#D0004B"}
                    text="Bewerken"
                    borderRadius="66px"
                    textColor="#FFFFFF"
                    width="40%"
                    ml="20px"
                    onClick={() => showMemberDetails(activeMember, false)}
                    disabled={activeMemberDetails?.isMobileOnBoarded}
                  /> */}
                  {/*<span style={{color: "#D0004B"}}>{isActiveEdit ? "Cancel" : "Edit"}</span>*/}
                </div>
              )}
            </div>
          </div>
          <div
            style={{ cursor: "pointer"}}
            title="Close"
            onClick={() => onHide(null, false)}
          >
            <Icon style={{ zIndex: 2000}}  iconName="cross" />
          </div>
        </div>
        <NextPaymentDateWrapper>
          <NextPaymentDateText>{t('Betaaldatum')}: {getNextPaymentDate()}</NextPaymentDateText>
        </NextPaymentDateWrapper>
        <div className="payment-small-details">
          {activeMemberDetails?.paymentMode && (
            <small className="monthly">
              {activeMemberDetails?.paymentMode === "manual"
                ? t("handmatig")
                : null}
              {activeMemberDetails?.paymentMode === "automatic"
                ? t("Automatisch")
                : null}
            </small>
          )}
          {activeMemberDetails?.paymentMethod && (
            <small className="autoPay">
              {activeMemberDetails?.paymentMethod}
            </small>
          )}
        </div>
      </Box>
      <Box
        className="profile-scroll member-deatil-button"
        style={{ top: "25px", position: "relative", pointerEvents:(activeMemberDetails?.isDeactivated || activeMemberDetails?.isMobileOnBoarded)?'none':'' }}
      >
        <Box className="details-card">
          <div className="d-flex ">
            <Icon iconName="user" mr="8px" />
            <h5>{t('PERSOONLIJKE DETAILS')}</h5>
          </div>
          {/* <Box mb="15px" className="d-flex justify-content-between member_ship">
                        <Box mr="20px">
                            <label color="#7E7E7E" mb="8px" fontSize="14px">Lidmaatschap sinds</label>
                            <Box style={{fontWeight: "600", fontSize: "16px"}}>
                            {formattedMembershipDate}
                            </Box>
                        </Box>
                    </Box> */}
          <Box className="d-flex justify-content-between">
            <div className="col-lg-6 input-width add-input-field-margin-profile-modal">
              <DisplayFIeldLabel title={t("Naam")} />
              <Input
                value={activeMemberDetails?.name}
                className="input-box input-box-profile-modal"
                onChange={(e) =>
                  handleUpdateDetails("contact_name", e.target.value)
                }
              />
              {errors?.name && <ErrorMessage>{t(errors.name)}</ErrorMessage>}
            </div>
            <div
              className="col-lg-6 input-width add-input-field-margin-profile-modal"
              style={{ marginLeft: "10px" }}
            >
              <DisplayFIeldLabel title={t("E-mail")} />

              <Input
                value={activeMemberDetails?.emailAddress}
                className="input-box input-box-profile-modal"
                onChange={(e) =>
                  handleUpdateDetails("contact_email", e.target.value)
                }
              />
              {errors?.emailAddress && (
                <ErrorMessage>{t(errors?.emailAddress)}</ErrorMessage>
              )}
            </div>
          </Box>
          <Box className="d-flex justify-content-between">
            <div className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal">
              <DisplayFIeldLabel title={t("Contactnummer")} />

              <Input
                value={activeMemberDetails?.phoneNumber}
                countryCodes={true}
                countryCodeValue={activeMemberDetails?.countryCode}
                setCountryCode={(e) => handleUpdateDetails("country_code", e)}
                onChange={(e) =>
                  handleUpdateDetails("contact_phone", e.target.value)
                }
                border={
                  activeMemberDetails?.phoneNumber?.length
                    ? "1px solid #606734"
                    : null
                }
                placeholder="785-785-4644"
                className="input-box input-box-profile-modal"
                type="number"
                pattern="[0-9]"
                overflow="unset"
              />
              {errors?.phoneNumber && (
                <ErrorMessage>{t(errors.phoneNumber)}</ErrorMessage>
              )}
              {errors?.countryCode && (
                <ErrorMessage>{t(errors.countryCode)}</ErrorMessage>
              )}
            </div>

            {!countries?.length ? (
              <div
                className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal"
                style={{ marginLeft: "10px" }}
              >
                <Spinner />
              </div>
            ) : (
              <div
                className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal"
                style={{ marginLeft: "10px" }}
              >
                <DisplayFIeldLabel title={t("Select Country")} />

                <SelectBoxWrapper>
                  <Select
                    size="middle"
                    value={activeMemberDetails?.country}
                    className="input-box-profile-modal"
                    filterOption={false}
                    defaultValue={t("Select Country")}
                    onChange={(value) => {
                      handleUpdateDetails("country", value);
                    }}
                    style={{ width: "100%", fontWeight: 600 }}
                    dropdownStyle={{ zIndex: 2000 }}
                    options={countries}
                  >
                    {/* <Select.Option value="time">Time</Select.Option>
        <Select.Option value="date">Date</Select.Option> */}
                  </Select>
                </SelectBoxWrapper>
                {errors?.country && (
                  <ErrorMessage>{t(errors.country)}</ErrorMessage>
                )}
              </div>
            )}
          </Box>
          <Box className="d-flex justify-content-between">
            {isFetchingCities ? (
              <div
                className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner />
              </div>
            ) : (
              <div
                className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal"
                style={{}}
              >
                <DisplayFIeldLabel title={t("Stad")} />

                <SelectBoxWrapper>
                  <Select
                    size="middle"
                    value={activeMemberDetails?.city}
                    className="input-box-profile-modal"
                    defaultValue={t("Select City")}
                    onChange={(value) => handleUpdateDetails("city", value)}
                    dropdownStyle={{ zIndex: 2000 }}
                    style={{ width: "100%", fontWeight: 600 }}
                    options={cities}
                  />
                </SelectBoxWrapper>
                {errors?.city && <ErrorMessage>{t(errors.city)}</ErrorMessage>}
              </div>
            )}
            <div
              className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal"
              style={{ marginLeft: "10px", position:'relative'}}
            >
              <DisplayFIeldLabel title={t("Lidmaatschap")} />
              <SelectBoxWrapper>
                <Select
                  size="middle"
                  value={
                    (activeMemberDetails?.subscription_plan_id )
                      ?  `${getSelectedPlan(activeMemberDetails?.subscription_plan_id).plan_name} - €${getSelectedPlan(activeMemberDetails?.subscription_plan_id).amount}`
                      : t("Gratis abonnem..")
                  }
                  dropdownStyle={{ zIndex: 2000 }}
                  className="input-box-profile-modal"
                  // disabled={activeMemberDetails?.isMobileOnBoarded}
                  disabled
                  defaultValue={t("Select Plan")}
                  onChange={(value) =>{
                    handleUpdateDetails("subscription_plan_id", value)}
                  }
                  style={{ width: "100%", fontWeight: 600 }}
                  options={optionsPlan()}
                />
                {errors?.subscription_plan_id && (
                  <ErrorMessage>{t(errors.subscription_plan_id)}</ErrorMessage>
                )}
              </SelectBoxWrapper>
              {!(activeMemberDetails?.isMobileOnBoarded || activeMemberDetails?.paymentMode === null ) && <Label onClick={()=>setShowChangeSub(true)} style={{position:'absolute', right:10,bottom:-2, color:'#D0004B',fontWeight:500, cursor:'pointer'}}>{t('Edit')}</Label>}
            </div>
          </Box>
          {activeMemberDetails?.customerAccountName ||
          activeMemberDetails?.customerIBAN ? (
            <Box className="d-flex justify-content-between">
              {activeMemberDetails?.customerAccountName && (
                <div className="col-lg-6 pt-2 input-width add-input-field-margin-profile-modal">
                  <DisplayFIeldLabel title={t("Customer Account name")} />

                  <input
                    value={activeMemberDetails?.customerAccountName}
                    type={isFocusedCustName ? 'text' : 'password'}
                    // onFocus={handleFocusCustName}
                    // onBlur={handleBlurCustName}
                    onChange={(e) =>
                      handleUpdateDetails("customer_acc_name", e.target.value)
                    }
                    className="input-box input-box-profile-modal"
                    disabled={ activeMemberDetails?.paymentMode === null}
                  />
                  {errors.customer_acc_name && (
                    <ErrorMessage>{t(errors.customer_acc_name)}</ErrorMessage>
                  )}
                </div>
              )}
              {activeMemberDetails?.customerIBAN && (
                <div
                  className="col-lg-6 pt-2 input-width"
                  style={{ marginLeft: "10px", marginTop:'10px' }}
                >
                  <DisplayFIeldLabel title={t("Customer IBAN")} />

                  <input
                    value={activeMemberDetails?.customerIBAN}
                    type={'password'}
                    // onFocus={handleFocusOne}
                    // onBlur={handleBlur}
                    disabled={ activeMemberDetails?.paymentMode === null}
                    onChange={(e) =>
                      handleUpdateDetails("customer_iban", e.target.value)
                    }
                    className="input-box input-box-profile-modal"
                  />
                  {errors.customer_iban && (
                    <ErrorMessage>{t(errors.customer_iban)}</ErrorMessage>
                  )}
                </div>
              )}
            </Box>
          ) : null}
        </Box>
        <div
          className="details-card"
          style={{ marginTop: "30px", marginBottom: "30px" }}
        >
          <div className="d-flex align-items-baseline">
            <Icon iconName="payment" mr="10px" />
            <h5>{t('BETALINGSGESCHIEDENIS')}</h5>
          </div>
          <div className="d-flex justify-content-between member_ship add-input-field-margin">
            <div>
              <DisplayFIeldLabel title={t("Totaal ontvangen betalingen")} />

              <h6 className="headingSix">
                {activeMemberDetails?.successPaymentsTotal}
              </h6>
            </div>
            <div>
              <DisplayFIeldLabel title={t("Openstaande betalingen")} />
              <h6 className="headingSix">
                {activeMemberDetails?.pendingPaymentsTotal}
              </h6>
            </div>
          </div>
          <div className="add-input-field-margin">
            <DisplayFIeldLabel
              classTitle={"label-missed-payments"}
              title={t("Vorige Gemiste betalingen")}
            />

            <h6 className="headingSix">
              {activeMemberDetails?.missedPaymentsTotal}
            </h6>
          </div>

          <div className="month-day">
            {MonthData &&
              MonthData.map((item) => (
                <div>
                  <h6>{item?.name}</h6>
                  {renderPaymentStatus(activeMemberDetails, item?.number)}
                </div>
              ))}
          </div>
        </div>
        <Box display="flex" justifyContent="center">
          <Box>
            <Button
              type="outlineColor"
              text={t("Cancel")}
              borderRadius="66px"
              minWidth="200px"
              height="50px"
              mb={4}
              onClick={() => onHide(null, false)}
            />
          </Box>
          <Box ml="15px">
            <Button
              type={
                (activeMemberDetails?.isMobileOnBoarded || !isDetailsUpdated)?"disabled":"primary"
              }
              text={t("Save Details")}
              borderRadius="66px"
              minWidth="200px"
              mb={4}
              disabled={activeMemberDetails?.isMobileOnBoarded || !isDetailsUpdated}
              height="50px"
              onClick={() => handleSubmit()}
            />
          </Box>
        </Box>
        {/*<div className="details-card">
                    <div className="d-flex">
                        <Icon
                            iconName="historyMsg"
                            mr="10px"
                        />
                        <h5>REMINDER HISTORY</h5>
                    </div>
                    <div className="d-flex">
                        <div className="sent-msg label-sent-msg mb-2">
                            <label style={{fontSize: "13px", minWidth: "130px"}}>Sent On</label>
                        </div>
                        <div className="message label-message mb-2">
                            <label style={{fontSize: "13px"}}>Message</label>
                        </div>
                    </div>
                    {reminder_history?.map((reminder, index) => (
                        <div className="d-flex" key={index}>
                            <div className="sent-msg date-container" style={{minWidth: "120px"}}>
                                <h3>{dayjs(new Date(reminder.created_at)).format('DD MMM YY')}</h3>
                                {index !== reminder_history.length - 1 && (
                                    <div className="vertical-dotted-line"></div>
                                )}
                            </div>
                            <div className="message label-msg">
                                <label style={{wordWrap: 'break-word'}}>{reminder.compiled_text}</label>
                            </div>
                        </div>
                    ))}
                    {!!reminder_history?.length && showLoadMoreButton !== historyPageNum &&
                        <Box mt="10px" display="flex" justifyContent="center">
                            <Button onClick={() => loadMoreButton(historyPageNum + 1)}>Load More</Button>
                        </Box>
                    }
                </div>*/}
      </Box>

      <DefaultModal
        show={isDisable}
        onHide={() => setIsDisable(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        {activeMember?.isDeactivated?<DisableMemberModal
          text={
            t("Wilt u de gebruiker inschakelen?")
          }
          handleDelete={() => updateStatus()}
          show={isDisable}
          setshow={setIsDisable}
          setComment = {setComment}
          comment={comment}
          isProfileModal = {true}
        />:<DisableMemberModal
          text={
            t("Wilt u de gebruiker uitschakelen?")
          }
          handleDelete={() => updateStatus()}
          show={isDisable}
          setshow={setIsDisable}
          setComment = {setComment}
          comment={comment}
          isProfileModal = {true}
        />}
        
      </DefaultModal>
      <DefaultModal
        show={showChangeSub}
        onHide={() => setShowChangeSub(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px"}}
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
       <div className="d-flex justify-content-between">
                <div>

                </div>
                <div onClick={() => setShowChangeSub(false)}>
                    <Icon
                        // style={{zIndex: 2000}}
                        iconName="blackCross"
                    />
                </div>
            </div>
            <div className="congrats-screen" style={{paddingInline:'20px'}} >
                {/* <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" /> */}
                <Box  style={{minHeight:activeMemberDetails?.paymentMode==='automatic'?'250px':'150px'}}>
                    <h4 style={{fontWeight:700}}>{t('Edit subscription')}</h4>
                    <DisplayFIeldLabel title={t("Payment Method")} />
                    <Select
                  size="middle"
                  value={ activeMemberDetails?.paymentMode }
                  dropdownStyle={{ zIndex: 2000 }}
                  className="input-box-profile-modal"
                  // disabled={activeMemberDetails?.isMobileOnBoarded}
                  defaultValue={t("Payment Method")}
                  onChange={(value) =>{
                    handleUpdateDetails("paymentMode", value)}
                  }
                  style={{ width: "100%", fontWeight: 600, marginBottom:'50px' }}
                  options={getPaymentMethods()}
                />

{activeMemberDetails?.paymentMode==='automatic' && <DisplayFIeldLabel title={t("Lidmaatschap")} />}
                    { activeMemberDetails?.paymentMode==='automatic' && <Select
                  size="middle"
                  value={
                    (activeMemberDetails?.subscription_plan_id )
                      ?  `${getSelectedPlan(activeMemberDetails?.subscription_plan_id).plan_name} - €${getSelectedPlan(activeMemberDetails?.subscription_plan_id).amount}`
                      : t("Gratis abonnement")
                  }
                  dropdownStyle={{ zIndex: 2000 }}
                  className="input-box-profile-modal"
                  // disabled={activeMemberDetails?.isMobileOnBoarded}
                  defaultValue={t("Select Plan")}
                  disabled={userRef?.current?.paymentMode==='automatic' }
                  onChange={(value) =>{
                    handleUpdateDetails("subscription_plan_id", value)}
                  }
                  style={{ width: "100%", fontWeight: 600 }}
                  options={optionsPlan()}
                />}
                </Box>
                { activeMemberDetails?.paymentMode==='automatic' && <Box className="d-flex justify-content-between" style={{flexWrap:'wrap'}}>
                <div className="col-lg-12 pt-2 input-width " style={{position:'relative'}}>
                  <div style={{display:'flex'}}>
                  <DisplayFIeldLabel  title={t("Customer Account name")} />
                  <span style={{ color: "#FE1717" }}>*</span>
                  </div>
                  <input
                    value={activeMemberDetails?.customerAccountName}
                    type={isFocusedCustName ? 'text' : 'password'}
                    // onFocus={handleFocusCustName}
                    // onBlur={handleBlurCustName}
                    onChange={(e) =>
                      handleUpdateDetails("customerAccountName", e.target.value)
                    }
                    className="input-box input-box-profile-modal"
                  />
                  <Icon iconName="eye" onClick={handleFocusCustName} color="grey" style={{position:'absolute', bottom:'20px',right:'10px'}}/>
                  {errors.customerAccountName && (
                    <ErrorMessage>{t(errors.customerAccountName)}</ErrorMessage>
                  )}
                </div>
                <div style={{display:'flex', gap:'5px',marginTop:'15px' }}>
                <div
                  className=" pt-2 input-width"
                  style={{position:'relative'}}
                  // style={{ marginLeft: "10px" }}
                >
                  <div style={{display:'flex'}}>
                  <DisplayFIeldLabel title={t("Customer IBAN")} />
                  <span style={{ color: "#FE1717" }}>*</span>
                  </div>
                  <input
                    value={activeMemberDetails?.customerIBAN}
                    type={isFocusedIBANOne ? 'text' : 'password'}
                    // onFocus={handleFocus}
                    // onBlur={handleBlur}
                    onChange={(e) =>
                      handleUpdateDetails("customerIBAN", e.target.value)
                    }
                    className="input-box input-box-profile-modal"
                  />
                  <Icon iconName="eye" color="grey" onClick={handleFocusOne} style={{position:'absolute', bottom:'20px',right:'10px'}}/>
                  {errors.customerIBAN && (
                    <ErrorMessage>{t(errors.customerIBAN)}</ErrorMessage>
                  )}
                </div>
                <div
                  className="col-lg-6 pt-2 input-width"
                  style={{position:'relative'}}
                  // style={{ marginLeft: "10px" }}
                >
                  <div style={{display:'flex'}}>
                  <DisplayFIeldLabel title={t('Herhaal ')+t("Customer IBAN")} />
                  <span style={{ color: "#FE1717" }}>*</span>
                  </div>
                  <input
                    value={compareIban}
                    type={isFocusedIBANTwo ? 'text' : 'password'}
                    onFocus={()=>setIsCompareIbanError(false)}
                    // onBlur={handleBlur}

                    onChange={(e) =>
                      setCompareIban(e.target.value)
                    }
                    className="input-box input-box-profile-modal"
                  />
                  <Icon iconName="eye" onClick={handleFocusTwo} color="grey" style={{position:'absolute', bottom:'20px',right:'10px'}}/>
                  {isCompareIbanError && (
                    <ErrorMessage>{t('IBAN not matching')}</ErrorMessage>
                  )}
                </div>
                </div>
               
            </Box>}
                {/* <Label color="#7E7E7E">{text}</Label> */}

                <div className="d-flex justify-content-around mt-3" >
                    <button className="prev-bt"  onClick={() => setShowChangeSub(false)}>
                    {t('Annuleren')}
                    </button>
                    <button className="fill-bt" style={{backgroundColor:shouldDisableSubChangeBtn()?"#CCCCCC" : "#D0004B"}} disabled={shouldDisableSubChangeBtn()} onClick={handleChangeSubscription}>
                    {t('Ja')}
                    </button>
                </div>
            </div>
      </DefaultModal>
    </Box>
  );
};

function DisplayFIeldLabel({ title, classTitle }) {
  return (
    <Label
      style={{
        fontSize: "16px",
        fontFamily: "Raleway",
        fontWeight: 400,
        color: "#7E7E7E",
        marginBottom: "5px",
      }}
      className={classTitle || ""}
    >
      {title}
    </Label>
  );
}
export default ProfileModal;
