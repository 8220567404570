import React, { useState, useEffect } from "react";
import DonationFormBanner from "./components/DonationFormBanner";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DonationFormCard from "./components/DonationFormCard";
import DonationFormInput from "./components/DonationFormInput";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { showNotification } from "../../../../util/Utility";
import translations from "./translations";

function DonationForm() {
  const [isSuccess, setIsSuccess] = useState(false);
  const {t} = useTranslation()
  const [searchParams] = useSearchParams();
  const campaignId = searchParams.get('campaignId');
  const shareId = searchParams.get('shareId')
  const platform = searchParams.get('platform')
  const language = searchParams.get('language');
  const [campaign, setCampaign] = useState(null)
  const isResetLinkSend = useSelector(
    (state) => state.adminReducer?.isResetLinkSend
  );

  useEffect(()=>{
    const getCampaign=async()=>{
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}donation/${campaignId}`)
            console.log('campaign--->',res.data);
            setCampaign(res.data)
            
        } catch (error) {
            console.log(error);
            showNotification('error',translations[language?.toUpperCase()]['error fetching campaign..'])
        }
    }
    getCampaign()
  },[campaignId])
  return (
    <div style={{marginBottom:'50px'}}>
      <DonationFormBanner language={language}/>
      <DonationFormCard language={language} campaign={campaign}/>
      <DonationFormInput language={language} campaign={campaign} shareId={shareId} platform={platform}/>
    </div>
  );
}

export default DonationForm;
