import {
    ADMIN_LOGIN_SUCCESS,
    FETCH_ACTIVE_MOSQUE_PLANS,
    FETCH_ACTIVE_MOSQUES,
    FETCHING_BLOG_DETAILS_DATA,
    FETCHING_BLOG_DETAILS_DATA_FAILURE,
    FETCHING_BLOG_DETAILS_DATA_SUCCESS,
    FETCHING_BLOG_LIST_DATA,
    FETCHING_BLOG_LIST_DATA_FAILURE,
    FETCHING_BLOG_LIST_DATA_SUCCESS,
    FETCHING_CAMPAIGN_LIST_DATA,
    FETCHING_CAMPAIGN_LIST_DATA_FAILURE,
    FETCHING_CAMPAIGN_LIST_DATA_SUCCESS,
    FETCHING_NOTIFICATION_LIST_DATA,
    FETCHING_NOTIFICATION_LIST_DATA_FAILURE,
    FETCHING_NOTIFICATION_LIST_DATA_SUCCESS,
    FETCHING_PAYMENT_HISTORY_DATA,
    FETCHING_PAYMENT_HISTORY_DATA_FAILURE,
    FETCHING_PAYMENT_HISTORY_DATA_SUCCESS,
    FETCHING_STATUS,
    IS_CUSTOMER_EXIST,
    NEW_ONBOARD,
    NEW_ONBOARD_FAILURE,
    NEW_ONBOARD_MEMBER,
    NEW_ONBOARD_MEMBER_FAILURE,
    NEW_ONBOARD_MEMBER_SUCCESS,
    NEW_ONBOARD_SUCCESS,
    OTP_SENT,
    OTP_SENT_FAILURE,
    OTP_SENT_SUCCESS,
    SET_ACTIVE_MEMBER,
    SET_CUSTOMER,
    SET_ONBOARDING_DETAILS,
    SET_ONBOARDING_MEMBER_DETAILS,
    SET_OTP_SENT,
    SET_QUERY_DETAILS,
    SET_QUERY_DETAILS_SUCCESS,
    SET_USER,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_FAILURE,
    UPDATE_CUSTOMER_SUCCESS,
    VALIDATE_OTP,
    VALIDATE_OTP_FAILURE,
    VALIDATE_OTP_SUCCESS_FOR_CUSTOMER,
    SET_IS_ONBOARDED,
    SET_IS_ONBOARDED_MEMBER,
    INITIAL_ONBOARDING_DETAILS,
    INITIAL_ONBOARDING_MEMBER_DETAILS,
    UPDATE_MEMBER_SUBSCRIPTION,
    UPDATE_MEMBER_SUBSCRIPTION_FAILURE,
    UPDATE_MEMBER_SUBSCRIPTION_SUCCESS,
    FETCHING_PENDING_PAYMENT_HISTORY_DATA,
    FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE, FETCHING_PENDING_PAYMENT_HISTORY_DATA_SUCCESS,
    SET_LANGUAGE
} from "./customerActionType";
import {callApiV2} from "../../util/apiCaller";
import {showNotification} from "../../util/Utility";
import {APP_TOKEN, USER_ID, USER_TYPE} from "../../constants/appEnums";
import jwt_decode from "jwt-decode";
import {AUTHENTICATED} from "../../constants/authEnums";
import {setAuthState, setUserId} from "../appAction";
import { fetchPlanList } from "../Mosque/mosqueAction";


export const setLanguage = (code) => ({
    type: SET_LANGUAGE,
    code,
});

export const setOnboardingDetails = (keyName, keyValue) => ({
    type: SET_ONBOARDING_DETAILS,
    keyName,
    keyValue
});

export const setOnboardingMemberDetails = (keyName, keyValue) => ({
    type: SET_ONBOARDING_MEMBER_DETAILS,
    keyName,
    keyValue
});

export const setOtpSent = (status) => ({
    type: SET_OTP_SENT,
    status,
});

export const setIsOnboarded = (status) => ({
    type: SET_IS_ONBOARDED,
    status,
});

export const setInitialOnboarded = () => ({
    type: INITIAL_ONBOARDING_DETAILS,
});

export const setInitialMemberOnboarded = () => ({
    type: INITIAL_ONBOARDING_MEMBER_DETAILS,
});

export const setIsOnboardedMember = (status) => ({
    type: SET_IS_ONBOARDED_MEMBER,
    status,
});

export const newMosqueOnboard = ({ onboardEntityType } = {}) => async (dispatch, getState) => {
    console.log("onboardtype : ", onboardEntityType);
    try {
        dispatch({
            type: NEW_ONBOARD,
            is_onboarding: true,
            is_error: false,
            error_message: ""
        });
        let onboardingDetails = getState()?.customerReducer?.onboardingDetails;
        
        const trimmedDetails = {...onboardingDetails,
            onboardEntityType: onboardEntityType};
        // Trim each attribute and assign it to trimmedDetails object
        for (const key in onboardingDetails) {
            if (typeof onboardingDetails[key] === "string") {
                trimmedDetails[key] = onboardingDetails[key].trim();
            } else {
                trimmedDetails[key] = onboardingDetails[key];
            }
        }
        console.log(trimmedDetails);
        if (trimmedDetails) {
            const res = await callApiV2({
                method: "post",
                endpoint: `onboarding/new-entity-signup`,
                body: trimmedDetails,
                isErrorSuppressed: true,
            });
            if (res?.status === "Success") {
                dispatch({
                    type: NEW_ONBOARD_SUCCESS,
                    payload: {},
                    is_onboarding: false,
                    is_onboarded: true
                });
                if (trimmedDetails.payment_method === "manual" && res?.data?.stripeResult) {
                    window.location = res?.data?.stripeResult;
                }
            } else {
                showNotification('error', res?.data?.message || "Something went wrong...")
                dispatch({
                    type: NEW_ONBOARD_FAILURE,
                    is_onboarding: false,
                    is_error: true,
                    error_message: res?.data?.message || "Something went wrong...",
                });
            }
        }
    } catch (err) {
        dispatch({
            type: NEW_ONBOARD_FAILURE,
            is_onboarding: false,
            is_error: true,
            error_message: "Something went wrong..."
        });
    }
};

export const newMemberOnboard = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: NEW_ONBOARD_MEMBER,
            is_onboarding_member: true
        });
        let memberDetails = getState()?.customerReducer?.memberDetails;
        const trimmedDetails = {};
        // Trim each attribute and assign it to trimmedDetails object
        for (const key in memberDetails) {
            if (typeof memberDetails[key] === "string") {
                trimmedDetails[key] = memberDetails[key].trim();
            } else {
                trimmedDetails[key] = memberDetails[key];
            }
        }

        if (trimmedDetails) {
            const res = await callApiV2({
                method: "post",
                endpoint: `onboarding/new-member-signup`,
                body: {
                    customer: trimmedDetails,
                },
                isErrorSuppressed: true
            });
            if (res?.status === "Success") {
                dispatch({
                    type: NEW_ONBOARD_MEMBER_SUCCESS,
                    payload: {},
                    is_onboarding_member: false,
                    is_onboarded_member: true
                });
                if (trimmedDetails.payment_method === "manual" && res?.data?.stripeResult) {
                    window.location = res?.data?.stripeResult;
                }
            } else {
                showNotification('error', res?.data?.message || "Something went wrong...")
                dispatch({
                    type: NEW_ONBOARD_MEMBER_FAILURE,
                    is_onboarding_member: false,
                    is_error: true,
                    error_message: res?.data?.message || "something went wrong",
                });
            }
        }
    } catch (err) {
        dispatch({
            type: NEW_ONBOARD_MEMBER_FAILURE,
            is_onboarding_member: false,
            is_error: true,
            error_message: "something went wrong"
        });
    }
};

export const setQueryDetails = (keyName, keyValue) => ({
    type: SET_QUERY_DETAILS,
    keyName,
    keyValue
});

export const submitQuery = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_STATUS,
            isFetching: true
        });
        let contact_us = getState()?.customerReducer?.contact_us;
        if (contact_us) {
            let update = {...contact_us};
            update.contact_name = `${contact_us.first_name} ${contact_us.last_name}`.trim();
            const res = await callApiV2({
                method: "post",
                endpoint: `query/new`,
                body: {
                    query: update,
                },
            });
            if (res?.status === "Success") {
                showNotification('success', "Your query is submitted successfully.");
                dispatch({
                    type: SET_QUERY_DETAILS_SUCCESS,
                    payload: res?.data?.query,
                    isFetching: false,
                });
            } else if (res?.status === "Error") {
                dispatch({
                    type: FETCHING_STATUS,
                    isFetching: false,
                });
            }
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_STATUS,
            isFetching: false,
        });
    }
};

export const customerLogin = (body, isResend=false) => async (dispatch) => {
    try {
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/auth/login-otp`,
            body: {customer: body},
            isErrorSuppressed: true
        });

        if (res?.status === "Success") {
            if (isResend) {
                showNotification("success", "Resend OTP");
            }
            if (!!res?.data?.is_customer_found) {
                dispatch({
                    type: OTP_SENT_SUCCESS,
                    isOtpSent: true,
                    isFetching: false,
                });
            } else if (!res?.data?.is_customer_found) {
                dispatch({
                    type: IS_CUSTOMER_EXIST,
                    is_customer_found: true,
                });
            }
        } else {
            showNotification("error", res?.data?.message?.message || res?.data?.message || "Something went wrong...");
        }
    } catch (err) {
        dispatch({
            type: OTP_SENT_FAILURE,
            isFetching: false,
        });
    }
};

export const validateOtpForCustomer = (body) => async (dispatch) => {
    try {
        dispatch({
            type: VALIDATE_OTP,
            isFetching: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/auth/validate-otp`,
            body: {customer: body},
        });

        if (res?.status === "Success") {
            const {token} = res?.data;
            window.localStorage.setItem(APP_TOKEN, token);
            window.localStorage.setItem(USER_TYPE, "customer");
            window.localStorage.setItem(USER_ID, res?.data?.customer?._id);
            const tempUser = jwt_decode(token);
            dispatch(setUser(tempUser));
            dispatch(setAuthState(AUTHENTICATED));

            showNotification("success", "Welcome to mosqapp customer dashboard.")
            dispatch({
                type: VALIDATE_OTP_SUCCESS_FOR_CUSTOMER,
                payload: res?.data,
                isValidate: true,
                isFetching: false,
            });

            if (res?.data?.customer?._id) {
                setUserId(res?.data?.customer?._id);
            }

            window.location = `/customer/${res?.data?.customer?._id}/home`;
        }
    } catch (err) {
        dispatch({
            type: VALIDATE_OTP_FAILURE,
            isFetching: false,
        });
    }
};

export const setUser = (user) => ({
    type: SET_USER,
    user,
});

export const setActiveMember = (member, member_subscription) => ({
    type: SET_ACTIVE_MEMBER,
    member,
    member_subscription
});

export const fetchBlogList = ({customer_id, pageNum = 1, pageSize = 12, filters}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_BLOG_LIST_DATA,
            is_fetching_blog_list: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/profile/customer/${customer_id}/blog-list`,
            body: {
                pageNum: pageNum,
                pageSize: pageSize,
                filters: filters
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_BLOG_LIST_DATA_SUCCESS,
                blog_list: res?.data?.blogs || [],
                total_blog_count: res?.data?.count || 0,
                blog_page_num: pageNum,
                blog_page_size: pageSize,
                is_fetching_blog_list: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_BLOG_LIST_DATA_FAILURE,
                is_fetching_blog_list: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_BLOG_LIST_DATA_FAILURE,
            is_fetching_blog_list: false,
        });
    }
};

export const fetchCampaignList = ({customer_id, pageNum = 1, pageSize = 12, filters = {}}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_CAMPAIGN_LIST_DATA,
            is_fetching_campaign_list: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/profile/customer/${customer_id}/campaign-list`,
            body: {
                pageNum: pageNum,
                pageSize: pageSize,
                filters: filters
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_CAMPAIGN_LIST_DATA_SUCCESS,
                campaign_list: res?.data?.campaigns || [],
                total_campaign_count: res?.data?.count || 0,
                campaign_page_num: pageNum,
                campaign_page_size: pageSize,
                is_fetching_campaign_list: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_CAMPAIGN_LIST_DATA_FAILURE,
                is_fetching_campaign_list: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_CAMPAIGN_LIST_DATA_FAILURE,
            is_fetching_campaign_list: false,
        });
    }
};

export const fetchBlogDetails = ({customer_id, url}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_BLOG_DETAILS_DATA,
            is_fetching_blog: true,
        });
        const res = await callApiV2({
            method: "get",
            endpoint: `customer/profile/customer/${customer_id}/blog/${url}/details`,
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_BLOG_DETAILS_DATA_SUCCESS,
                blog: res?.data?.blog || {},
                is_fetching_blog: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_BLOG_DETAILS_DATA_FAILURE,
                is_fetching_blog: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_BLOG_DETAILS_DATA_FAILURE,
            isFetching: false,
        });
    }
};

export const fetchPaymentHistory = ({customer_id, pageNum = 1, pageSize = 10}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_PAYMENT_HISTORY_DATA,
            is_fetching_payment_history_list: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/profile/customer/${customer_id}/payment-list`,
            body: {
                "pageNum": pageNum,
                "pageSize": pageSize,
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_PAYMENT_HISTORY_DATA_SUCCESS,
                payment_history_list: res?.data?.payments || [],
                total_payment_history_count: res?.data?.total_count || 0,
                payment_history_page_num: pageNum,
                payment_history_page_size: pageSize,
                is_fetching_payment_history_list: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_PAYMENT_HISTORY_DATA_FAILURE,
                is_fetching_payment_history_list: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_PAYMENT_HISTORY_DATA_FAILURE,
            is_fetching_payment_history_list: false,
        });
    }
};

export const fetchPendingPaymentHistory = ({customer_id, pageNum = 1, pageSize = 30}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_PENDING_PAYMENT_HISTORY_DATA,
            isFetching: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/profile/customer/${customer_id}/payment-list`,
            body: {
                pageNum: pageNum,
                pageSize: pageSize,
                filters: {
                    payment_status: "pending"
                }
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_PENDING_PAYMENT_HISTORY_DATA_SUCCESS,
                payment_history_list: res?.data?.payments || [],
                total_payment_history_count: res?.data?.total_count || 0,
                isFetching: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE,
                isFetching: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_PENDING_PAYMENT_HISTORY_DATA_FAILURE,
            isFetching: false,
        });
    }
};

export const fetchNotification = ({customer_id, pageNum = 1, pageSize = 10}) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FETCHING_NOTIFICATION_LIST_DATA,
            is_fetching_notification_list: true,
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/profile/customer/${customer_id}/notifications-list`,
            body: {
                "pageNum": pageNum,
                "pageSize": pageSize,
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCHING_NOTIFICATION_LIST_DATA_SUCCESS,
                notification_list: res?.data?.notifications || [],
                total_notification_count: res?.data?.count || 0,
                notification_page_num: pageNum,
                notification_page_size: pageSize,
                is_fetching_notification_list: false,
            });
        } else if (res?.status === "Error") {
            dispatch({
                type: FETCHING_NOTIFICATION_LIST_DATA_FAILURE,
                is_fetching_notification_list: false,
            });
        }
    } catch (err) {
        showNotification("error", "Something went wrong...");
        dispatch({
            type: FETCHING_NOTIFICATION_LIST_DATA_FAILURE,
            is_fetching_notification_list: false,
        });
    }
};

export const updateCustomer = (id, details, isPlanUpdate) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_CUSTOMER,
            isFetching: true
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/${id}/update`,
            body: {
                customer: details,
            },
        });
        if (res?.status === "Success") {
            if (!isPlanUpdate) {
                showNotification('success', "Customer details updated successfully.")
            }
            dispatch({
                type: UPDATE_CUSTOMER_SUCCESS,
                details: res?.data,
                isFetching: false,
            });
        } else {
            showNotification('error', res?.data?.message || "Something went wrong...")
            dispatch({
                type: UPDATE_CUSTOMER_FAILURE,
                isFetching: false,
            });
        }
    } catch (err) {
        dispatch({
            type: UPDATE_CUSTOMER_FAILURE,
            isFetching: false,
        });
    }
};

export const updateMemberSubscription = (id, details, isPlanUpdate = false) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPDATE_MEMBER_SUBSCRIPTION,
            isFetching: true
        });
        const res = await callApiV2({
            method: "post",
            endpoint: `member_subscription/${id}/update`,
            body: {
                member_subscription: details,
            },
        });
        if (res?.status === "Success") {
            dispatch({
                type: UPDATE_MEMBER_SUBSCRIPTION_SUCCESS,
                details: res?.data?.member_subscription,
                isFetching: false,
            });

            if (isPlanUpdate) {
                showNotification("success",  "Plan updated successfully.");
            }
        } else {
            showNotification('error', res?.data?.message || "Something went wrong...")
            dispatch({
                type: UPDATE_MEMBER_SUBSCRIPTION_FAILURE,
                isFetching: false,
            });
        }
    } catch (err) {
        dispatch({
            type: UPDATE_MEMBER_SUBSCRIPTION_FAILURE,
            isFetching: false,
        });
    }
};

export const fetchCustomerProfile = (customer_id) => async (dispatch, getState) => {
    try {
        let blog_page_num = getState()?.customerReducer?.blog_page_num;
        let blog_page_size = getState()?.customerReducer?.blog_page_size;

        const res = await callApiV2({
            method: "get",
            endpoint: `customer/${customer_id}/profile/info`,
        });
        dispatch(setUser(res.data?.customer));
        dispatch(setActiveMember(res.data?.members[0], res.data?.memberSubscriptions[0]));
        dispatch(setAuthState(AUTHENTICATED));
        dispatch(fetchPlanList(res?.data?.memberSubscriptions[0]?.entity_id));

        let user = res.data?.customer;
        let activeMemberSubscription = res?.data?.memberSubscriptions[0];

        if (user?._id && blog_page_num && blog_page_size && activeMemberSubscription?.entity_id &&
            activeMemberSubscription?.organisation_id) {
            let input = {};
            input.customer_id = user?._id;
            input.pageNum = blog_page_num;
            input.pageSize = blog_page_size;
            input.filters = {};
            input.filters.entity_id = activeMemberSubscription?.entity_id;
            input.filters.organisation_id = activeMemberSubscription?.organisation_id;
            dispatch(fetchBlogList(input));
        }

        dispatch({
            type: ADMIN_LOGIN_SUCCESS,
            isLoggingIn: false,
            isAuthenticated: true,
        });
    } catch (err) {

    }
}

export const fetchActiveMosques = () => async (dispatch) => {
    try {
        const res = await callApiV2({
            method: "post",
            endpoint: `customer/active-mosque-list`,
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCH_ACTIVE_MOSQUES,
                mosqueList: res?.data?.mosqueList
            })
        }
    } catch (err) {

    }
}

export const fetchActiveMosquePlans = (entity_id) => async (dispatch) => {
    try {
        const res = await callApiV2({
            method: "get",
            endpoint: `customer/entity/${entity_id}/active-plans`,
        });
        if (res?.status === "Success") {
            dispatch({
                type: FETCH_ACTIVE_MOSQUE_PLANS,
                activeMosquePlans: res?.data?.plans
            })
        }
    } catch (err) {

    }
}
