const translations = {
    NL: {
      'Thanks for your payment!': 'Bedankt voor uw betaling!',
      'Open App': 'Open de app.',
      'Payment is failed. Please open the app and try again.': 'Betaling is mislukt. Open de app en probeer het opnieuw.',
      'Payment is processing. Please open the app and check the status in sometime.': 'Betaling wordt verwerkt. Open de app en controleer over een tijdje de status.',
      'Thankyou for registering, a mail has been sent to your registered e-mail Id.': 'Bedankt voor uw registratie, er is een e-mail verzonden naar uw geregistreerde e-mailadres.',
      'Open Site': 'Open de site',
      Retry: 'Opnieuw proberen',
      'HELP YOUR MOSQUE':'HELP JE MOSKEE',
      'Volledige naam':'Volledige naam',
      "Voer naam in":"Voer naam in",
      "E-mailadres":"E-mailadres",
      "Voer uw E-mailadres In":"Voer uw E-mailadres In",
      "Contactnummer":"Contactnummer",
      "Amount":"Bedrag",
      'Make my donation anonymous':"Maak mijn donatie anoniem",
      'Donate Now':"Doneer nu",
      "name is required":"Naam is vereist",
      "Amount is required":"Bedrag is vereist",
      "Amount should be less than pending donation and not 0":"Het bedrag moet minder zijn dan de openstaande donatie en mag niet 0 zijn",
      "Phone number is required":"Telefoonnummer is vereist",
      "Enter valid phone number":"Voer een geldig telefoonnummer in",
      "email is required":"E-mailadres is vereist",
      "Enter valid email address":"Voer een geldig e-mailadres in",
      'some error occured..':'Er is een fout opgetreden..',
      'error fetching campaign..':"fout bij het ophalen van de campagne..",
      'Payment Successful':'Betaling geslaagd'
    },
    EN: {
      'Thanks for your payment!': 'Thanks for your payment!',
      'Open App': 'Open App',
      'Payment is failed. Please open the app and try again.': 'Payment is failed. Please open the app and try again.',
      'Payment is processing. Please open the app and check the status in sometime.': 'Payment is processing. Please open the app and check the status in sometime.',
      'Thankyou for registering, a mail has been sent to your registered e-mail Id.': 'Thankyou for registering, a mail has been sent to your registered e-mail Id.',
      'Open Site': 'Open Site',
      Retry: 'Retry',
      'HELP YOUR MOSQUE':'HELP YOUR MOSQUE',
      'Volledige naam':'Full name',
      "Voer naam in":"Enter name",
      "E-mailadres":"Email address",
      "Voer uw E-mailadres In":"Enter your email address",
      "Contactnummer":"Contact number",
      "Amount":"Amount",
      'Make my donation anonymous':'Make my donation anonymous',
      'Donate Now':'Donate Now',
      "name is required":"name is required",
      "Amount is required":"Amount is required",
      "Amount should be less than pending donation and not 0":"Amount should be less than pending donation and not 0",
      "Phone number is required":"Phone number is required",
      "Enter valid phone number":"Enter valid phone number",
      "email is required":"email is required",
      "Enter valid email address":"Enter valid email address",
      'some error occured..':'some error occured..',
      'error fetching campaign..':'error fetching campaign..',
      'Payment Successful':'Payment Successful'
    },
    GR: {
      'Thanks for your payment!': 'Vielen Dank für Ihre Zahlung!',
      'Open App': 'App öffnen',
      'Payment is failed. Please open the app and try again.': 'Zahlung fehlgeschlagen. Bitte öffnen Sie die App und versuchen Sie es erneut.',
      'Payment is processing. Please open the app and check the status in sometime.': 'Zahlung wird verarbeitet. Bitte öffnen Sie die App und überprüfen Sie den Status in Kürze.',
      'Thankyou for registering, a mail has been sent to your registered e-mail Id.': 'Danke für Ihre Registrierung, eine E-Mail wurde an Ihre registrierte E-Mail-Adresse gesendet.',
      'Open Site': 'Seite öffnen',
      Retry: 'Erneut versuchen',
      'HELP YOUR MOSQUE':'HILF DEINER MOSCHEE',
      'Volledige naam':'Vollständiger Name',
      "Voer naam in":"Name eingeben",
      "E-mailadres":"E-Mail-Adresse",
      "Voer uw E-mailadres In":"Geben Sie Ihre E-Mail-Adresse ein",
      "Contactnummer":"Kontaktnummer",
      "Amount":"Betrag",
      'Make my donation anonymous':'Mache meine Spende anonym',
      'Donate Now':'Jetzt spenden',
      "name is required":"Name ist erforderlich",
      "Amount is required":"Betrag ist erforderlich",
      "Amount should be less than pending donation and not 0":"Der Betrag sollte geringer sein als die ausstehende Spende und nicht 0 betragen",
      "Phone number is required":"Telefonnummer ist erforderlich",
      "Enter valid phone number":"Geben Sie eine gültige Telefonnummer ein",
      "email is required":"E-Mail ist erforderlich",
      "Enter valid email address":"Geben Sie eine gültige E-Mail-Adresse ein",
      'some error occured..':'Es ist ein Fehler aufgetreten..',
      'error fetching campaign..':"Fehler beim Abrufen der Kampagne..",
      'Payment Successful':'Zahlung erfolgreich'
    },
    FR: {
      'Thanks for your payment!': 'Merci pour votre paiement !',
      'Open App': 'Ouvrir l\'application',
      'Payment is failed. Please open the app and try again.': 'Le paiement a échoué. Veuillez ouvrir l\'application et réessayer.',
      'Payment is processing. Please open the app and check the status in sometime.': 'Le paiement est en cours. Veuillez ouvrir l\'application et vérifier le statut dans un moment.',
      'Thankyou for registering, a mail has been sent to your registered e-mail Id.': 'Merci pour votre inscription, un e-mail a été envoyé à votre identifiant e-mail enregistré.',
      'Open Site': 'Ouvrir le site',
      Retry: 'Réessayer',
      'HELP YOUR MOSQUE':'AIDEZ VOTRE MOSQUÉE',
      'Volledige naam':"Nom complet",
      "Voer naam in":"Entrez le nom",
      "E-mailadres":"Adresse e-mail",
      "Voer uw E-mailadres In":"Entrez votre adresse e-mail",
      "Contactnummer":"Numéro de contact",
      "Amount":"Montant",
      'Make my donation anonymous':'Rendre mon don anonyme',
      'Donate Now':'Faire un don maintenant',
      "name is required":"Le nom est requis",
      "Amount is required":"Le montant est requis",
      "Amount should be less than pending donation and not 0":"Le montant doit être inférieur à la donation en attente et ne pas être égal à 0",
      "Phone number is required":"Le numéro de téléphone est requis",
      "Enter valid phone number":"Entrez un numéro de téléphone valide",
      "email is required":"L'e-mail est requis",
      "Enter valid email address":"Entrez une adresse e-mail valide",
      'some error occured..':'Une erreur est survenue..',
      'error fetching campaign..':"erreur lors de la récupération de la campagne..",
      'Payment Successful':'Paiement réussi'
    },
  };
  
  module.exports = translations;
  