import React from 'react'
import { useSearchParams } from 'react-router-dom'
import DonationFormBanner from './components/DonationFormBanner'
import { useTranslation } from 'react-i18next'
import translations from './translations'

function DonationSuccess() {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams();
    const language = searchParams.get('language');
  return (
    <div>
        <DonationFormBanner language={language}/>
        <div className='donation-form-card' style={{flexDirection:'column',alignItems:'center', maxWidth:'450px',padding:'10px'}}>
           <img src='/assets/stamp1.svg' height={'140px'} width={'140px'}/>
           <p style={{fontWeight:'700', fontSize:'18px', color:'#1a1a1a', textAlign:'center'}}>{translations[language]['Payment Successful']}</p>
           <p style={{fontSize:'16px', fontWeight:'500', color:'#7E7E7E', textAlign:'center'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
    </div>
  )
}

export default DonationSuccess