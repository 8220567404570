import React ,{useEffect, useState}from 'react'
import { Helmet } from 'react-helmet'
import Input from '../../../../../components/Input'
import { getFormattedTime } from '../../../../../util/timeUtility'
import { useTranslation } from 'react-i18next'
import ErrorMessage from './DonationErrorMessage'
import Checkbox from 'antd/es/checkbox/Checkbox'
import Label from '../../../../../components/Label'
import Button from '../../../../../components/Buttons'
import { showNotification } from '../../../../../util/Utility'
import { v4 as uuidv4 } from "uuid";
import axios from 'axios'
import translations from '../translations'

function DonationFormInput({campaign, shareId, platform,language}) {
    const {t} = useTranslation()
    const [users, setUserData] = useState({
        name: "",
        email:"",
        amount: "",
        phone:""
      });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)  
    const [isAnonymous, setIsAnonymous] = useState(false)  
    const [isLoading, setIsLoading] = useState(false);
      const [errors, setErrors] = useState({});
    const [currentTranslations, setCurrentTranslations]= useState('EN')

      const handleChangeUser = (keyName, keyValue) => {
        setIsButtonDisabled(false)
        setErrors({})
        let update = { ...users };
        if (keyName === "contact_email") {
          keyValue = keyValue.toLowerCase();
        }
        update[keyName] = keyValue;
        setUserData(update);
      };   

 const isValidEmail = (email)=>{
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailRegex.test(email)
 }

 const isValidAmount = (amount)=>{
    // console.log(Number(amount), Number(campaign?.amount), Number(campaign?.recievedDonation));
    
   return (Number(amount)<= (Number(campaign?.amount) - Number(campaign?.recievedDonation))) && Number(amount)>0 
 }

 const validateBlog = (users) => {
    let isValid = true;
    let message = {};
    if (!users?.name?.trim()) {
      isValid = false;
      message["name"] = currentTranslations["name is required"];
    }

    if (!Number.isFinite(users?.amount) && !users?.amount?.trim()) {
      isValid = false;
      message["amount"] = currentTranslations["Amount is required"];
    }
    if ( users?.amount?.trim() && !isValidAmount(users?.amount)) {
        isValid = false;
        message["amount"] = currentTranslations["Amount should be less than pending donation and not 0"];
      }
    if (!users?.phone?.trim()) {
      isValid = false;
      message["phone"] = currentTranslations["Phone number is required"];
    }
    if (users?.phone?.trim() && users?.phone?.length!==9) {
        isValid = false;
        message["phone"] = currentTranslations["Enter valid phone number"];
      }
    if (!users?.email?.trim()) {
        isValid = false;
        message["email"] = currentTranslations["email is required"];
      }
    if (users?.email?.trim() && !isValidEmail(users?.email)) {
        isValid = false;
        message["email"] = currentTranslations["Enter valid email address"];
      }
    return {
      isValid,
      message,
    };
  };
 
 const handleSubmit = async()=>{
    try {
        setIsLoading(true)
        let check = validateBlog(users);
        
        if(check.isValid){
            console.log("valid data", users);
            let newDonate = { ...users };
        // newDonate.entity_id = entity_id;
        // newDonate.organisation_id = activeEntity.organisation_id;

        const trimmedDetails = {};
        
        for (const key in newDonate) {
          if (typeof newDonate[key] === "string") {
            trimmedDetails[key] = newDonate[key].trim();
          } else {
            trimmedDetails[key] = newDonate[key];
          }
        }
         console.log('valid trimmed', trimmedDetails);
         const payload = {
            paymentMode:'manual',
            paymentId:uuidv4(),
            userId:uuidv4(),
            entityId: campaign?.entity_id,
            campaignId: campaign?._id,
            isAnonymous,
            ...trimmedDetails
         }
        console.log(payload);
          const res = await axios.post(`${process.env.REACT_APP_API_URL}donation/create-transaction?origin=web&shareId=${shareId}&platform=${platform}&formLanguage=${language}`,payload);
          console.log(res.data.requiredAction.redirectURL);
          window.open(res?.data?.requiredAction?.redirectURL,"_self")
          setIsLoading(false)  
        }
        else{
            setIsLoading(false)
            setErrors(check.message)
        }
    } catch (error) {
        setIsLoading(false)
        console.log(error);
        showNotification('error',currentTranslations['some error occured..'])
    }
 }

 useEffect(()=>{
 const temp = translations[language.toUpperCase()] || translations['EN']
 setCurrentTranslations(temp)
 },[language])
 
// console.log(currentTranslations,language.toUpperCase(),currentTranslations["email is required"] );

  return (
    <div className="add_form donation-form-input" >
      <Helmet>
        <title>Mosqapp</title>
      </Helmet>
      <div className="mosque-form" >
        {/* <h2>Add Member</h2> */}
        <div className="row">
          <div className="col-lg-6 input-width add-input-field-margin">
            <label mb="8px" isMandatory>
              {currentTranslations['Volledige naam']} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={users?.name}
              onChange={(e) => handleChangeUser("name", e.target.value)}
              type="text"
              placeholder={currentTranslations["Voer naam in"]}
              className="input-box"
            />
            {errors.name && (
              <ErrorMessage>{errors.name}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 input-width add-input-field-margin">
            <label mb="8px" isMandatory>
              {currentTranslations["E-mailadres"]} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
            //   onBlur={() => handleEmailAndPhoneValidation()}
              value={users?.email}
              onChange={(e) =>
                handleChangeUser("email", e.target.value)
              }
              type="text"
              placeholder={currentTranslations["Voer uw E-mailadres In"]}
              className="input-box"
            //   disabled={isToBeApproved}
            />
            {errors.email && (
              <ErrorMessage>{errors.email}</ErrorMessage>
            )}
          </div>
          <div className="col-lg-6 pt-2 input-width add-input-field-margin">
            <label mb="8px" isMandatory>
              {currentTranslations["Contactnummer"]} <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
            //   onBlur={() => handleEmailAndPhoneValidation()}
              value={users?.phone}
              countryCodes={true}
              countryCodeValue={users?.country_code}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
            //   isDisabled={isToBeApproved}
              onChange={(e) =>
                handleChangeUser("phone", e.target.value)
              }
              border={users?.phone?.length ? "1px solid #606734" : null}
              placeholder="785-785-464"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
            {errors.phone && (
              <ErrorMessage>{errors.phone}</ErrorMessage>
            )}
          </div>
            
          <div className="col-lg-6 pt-4">
            <label>
              {currentTranslations["Amount"]}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={users?.amount}
              onChange={(e) => handleChangeUser("amount", e.target.value)}
              type="number"
              placeholder={currentTranslations["Enter Amount"]}
              className="input-box"
            />
            {errors?.amount ? (
              <ErrorMessage>{errors?.amount}</ErrorMessage>
            ) : null}
          </div>
          <div style={{display:'flex', gap:'5px',alignItems:'center', marginTop:'15px'}}>
          <Checkbox  value={isAnonymous} onChange={()=>setIsAnonymous(!isAnonymous)}/>
          <label style={{fontSize:'12px', color:'#1a1a1a',}}>{currentTranslations['Make my donation anonymous']}</label>
          </div>
          <Button  type={(isButtonDisabled || isLoading) ? "disabled" : "primary"} onClick={handleSubmit}  width="132px" margin="0 auto">{currentTranslations['Donate Now']}</Button>
          </div>
      </div>
      </div>
  )
}

export default DonationFormInput