import React, {useEffect, useState} from "react";
import Table from "../../../../../components/Table/Table";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Searchbar from "../../../../../components/Searchbar";
import links from "../../../../../constants/customerFilters.json";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import ProfileModal from "../../Members/components/ProfileModal";
import {useDispatch, useSelector} from "react-redux";
import {fetchCustomerInfoList, sendMemberReminders, setMemberDetailsVisible,} from "../../../mosqueAction";
import Button from "../../../../../components/Buttons";
import Checkbox from "../../../../../components/Checkbox";
import {isMonthUpcoming, showNotification} from "../../../../../util/Utility";
import dayjs from "dayjs";
import CheckboxMenu from "./../../Members/components/CheckBoxMenu";
import styled from "styled-components";
import truncate from "lodash/truncate";
import { useTranslation } from "react-i18next";
import {t} from "i18next"
import { background, maxHeight } from "styled-system";

const BoxContainer = styled(Box)`
  background: #f5f5f5;
  width: 220px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  height: 38px;
`;

const PreviewTableData = [
    {
        heading: t("Alle"),
    },
    {
        heading: t("Naam"),
    },
    {
        heading: t("Openstaand bedrag"),
    },
    {
        heading: t("Laatste betalingsdatum"),
    },
    {
        heading: t("Laatste herinneringsdatum"),
    },
];

function MemberTable({
                         isFilterBlocked,
                         blockedView,
                         blockedViewTitle,
                         infoPage = true,
                     }) {
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const [previewTableData, setPreviewTableData] = useState([
        {
            heading: t("Alle"),
        },
        {
            heading: t("Naam"),
        },
        {
            heading: t("Openstaand bedrag"),
        },
        {
            heading: t("Laatste betalingsdatum"),
        },
        {
            heading: t("Laatste herinneringsdatum"),
        }
    ])
    const [qtext, setValue] = useState("");
    const bizIndustryLinks = links;
    const [filters, setFilters] = useState({payment_statuses: ["unpaid"]});
    const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
    const isMemberModalVisible = useSelector(
        (state) => state.mosqueReducer.isMemberModalVisible
    );

    const customerList = useSelector(
        (state) => state.mosqueReducer.customerInfo?.customerInfoList
    );
    const is_sending_reminder = useSelector(
        (state) => state.mosqueReducer.is_sending_reminder
    );
    const customerInfoCount = useSelector(
        (state) => state.mosqueReducer.customerInfo?.customerInfoCount
    );
    const customerPageNum = useSelector(
        (state) => state.mosqueReducer.customerInfo?.pageNum
    );
    const customerPageSize = useSelector(
        (state) => state.mosqueReducer.customerInfo?.pageSize
    );
    const customerInfoPayments = useSelector(
        (state) => state.mosqueReducer.customerInfo?.customerInfoPayments
    );
    const isFetching = useSelector(
        (state) => state.mosqueReducer?.customerInfo?.isFetching
    );

    const [selectedUsers, setSelectedUser] = useState({});

    useEffect(() => {
        if (activeEntity?._id) {
            console.log("api called", filters);
            dispatch(
                fetchCustomerInfoList({
                    entity_id: activeEntity?._id,
                    pageSize: customerPageSize,
                    pageNum: customerPageNum,
                    filters,
                })
            );
        }
    }, [dispatch, activeEntity]);

    const getStatus = () => {
        if (!!filters?.length) {
            return filters.find((item) => item.payment_status === "unpaid");
        }
    };

    const handleFilterChange = ({pageNum, filters}) => {
        console.log(pageNum, filters);
        dispatch(
            fetchCustomerInfoList({
                entity_id: activeEntity?._id,
                pageSize: customerPageSize,
                pageNum: pageNum,
                filters,
            })
        );
    };

    const renderPaymentStatus = (item, month) => {
        const filteredData = customerInfoPayments[item?.member_id]?.filter(
            (payment) => {
                return dayjs(new Date(payment?.payment_due_date)).format("MMM").toLowerCase() === month
            }
        );
        if (isMonthUpcoming(month)) {
            return null;
        } else if (filteredData?.length > 0) {
            return (
                <Box
                    className={
                        filteredData?.[0]?.payment_status !== "paid"
                            ? "red-color"
                            : "green-color"
                    }
                ></Box>
            );
        } else {
            return <Box ml="7px">{"-"}</Box>;
        }
    };

    const handleSetUser = (id) => {
        let newUser = {...selectedUsers};
        newUser[id] = !newUser[id] ? 1 : 0;
        setSelectedUser(newUser);
    };

    /*const showMemberDetails = (item, val = true) => {
        dispatch(setMemberDetailsVisible(item, val));
    };*/

    const renderBlockedUnpaidMemberList = () => {
        return customerList?.map((item, index) => {
            return [
                !!getStatus() && getStatus()?.payment_status === "unpaid" ?
                    <div style={{display: "block"}}>
                        <Checkbox
                            checked={!!selectedUsers[item?._id]}
                            onChange={() => handleSetUser(item?._id)}
                        />
                    </div>
                    : index + 1,
                <div
                    /*onClick={() => showMemberDetails(item)}*/
                    style={{display: "block"}}>
                    <h6 style={{cursor: "pointer"}}
                        title={item?.name || 'NA'}
                        onMouseOver={(e) => (e.target.title = item?.name)}
                        onMouseOut={(e) => (e.target.title = item?.name ? truncate(item?.name, {length: 15}) : 'NA')}
                    >{item?.name ? truncate(item?.name, {length: 40}) : 'NA'}</h6>
                    <div>
                    <small
                            style={{
                                color: "#d9534f",
                                background: "#f5f5f5",
                                borderRadius: "4px",
                                paddingLeft:'2px',
                                paddingRight:'2px',
                                marginRight: (item?.isMobileOnBoarded)?"0px":"8px"
                            }}
                        >
                            {(item?.isMobileOnBoarded)?null: t('Offline')}
                        </small>
                        <small
                            style={{
                                color: "#4763E4",
                                background: "#E3F2FD",
                                borderRadius: "4px",
                            }}
                        >
                            {item?.paymentMode  === "manual" ? t("handmatig") : null}
                            {item?.paymentMode  === "automatic" ? t("Automatisch") : null}
                        </small>
                        <small
                            style={{
                                color: "#00AC4F",
                                background: "#E5F7ED",
                                borderRadius: "4px",
                                marginLeft: "8px"
                            }}
                        >
                            {item?.paymentMethod}
                        </small>
                    </div>
                </div>,
                <Box ml="20px">
                    € {item?.pendingPaymentsTotal+ item?.missedPaymentsTotal}
                </Box>,
                <Box ml="20px">
                    {item?.lastPaymentDate ? dayjs(new Date(item?.lastPaymentDate)).format('DD/MMM/YYYY') : '-'}
                </Box>,
                <Box ml="20px">
                    {item?.paymentRemainderDate ? dayjs(new Date(item?.paymentRemainderDate)).format('DD/MMM/YYYY') : '-'}
                </Box>
            ];
        });
    };

    const getConvertedMemberIds = () => {
        let member_subscriptions = [];
        for (const key in selectedUsers) {
            member_subscriptions.push(key);
        }
        return member_subscriptions;
    };

    const sendReminder = async () => {
        let members = getConvertedMemberIds();
        if (members.length > 0) {
            dispatch(
                sendMemberReminders({
                    entity_id: activeEntity?._id,
                    members,
                })
            );
        } else {
            showNotification("error", "Please Select Users");
        }
    };

    const hasAttributeWithValueOne = Object.values(selectedUsers).some(
        (value) => value === 1
    );

    const handleRedirection = () => {
        window.location.href = `/mosque/${activeEntity?._id}/info`;
    };

    const handleOnClick = () => {
        if (Object.keys(selectedUsers)?.length === customerInfoCount) {
            setSelectedUser({});
        } else {
            setSelectedUser({});
            let newUser = {};
            customerList.map((item) => {
                newUser[item?._id] = !newUser[item?._id] ? 1 : 0;
            });
            setSelectedUser(newUser);
        }
    };

    const isAllAttributesSetToOne = () => {
        if (Object.keys(selectedUsers)?.length === customerInfoCount) {
            return !!Object.keys(selectedUsers).length
                ? Object.values(selectedUsers).every((value) => value === 1)
                : false;
        }
        return false;
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            if (activeEntity?._id) {
                const filteredDetails = {...filters};
                filteredDetails.qtext = qtext;
                dispatch(
                    fetchCustomerInfoList({
                        entity_id: activeEntity?._id,
                        pageSize: customerPageSize,
                        pageNum: 1,
                        filters: filteredDetails,
                    })
                );
            }
        }
    };

    const handleButton = (val) => {
        if (val === "") {
            if (activeEntity?._id) {
                const filteredDetails = {...filters};
                dispatch(
                    fetchCustomerInfoList({
                        entity_id: activeEntity?._id,
                        pageSize: customerPageSize,
                        pageNum: customerPageNum,
                        filters: filteredDetails,
                    })
                );
            }
        }
        setValue(val);
    };

    const onCheckboxChange = (selection) => {
        let updatedFilters = {};
        setFilters(selection);
        const payment_statuses_data = selection.filter(
            (item) => item["payment_status"]
        );
        const recurring_types_data = selection.filter(
            (item) => item["recurring_type"]
        );
        const payment_methods_data = selection.filter(
            (item) => item["payment_method"]
        );
        const customer_statuses_data = selection.filter(
            (item) => item["customer_status"]
        );
        if (!!payment_statuses_data?.length) {
            updatedFilters["payment_statuses"] = payment_statuses_data.map(
                (item) => item.payment_status
            );
            if (updatedFilters["payment_statuses"]?.includes("all")) {
                updatedFilters["payment_statuses"] = updatedFilters[
                    "payment_statuses"
                    ].filter((status) => status !== "all");
            }
        }
        if (!!recurring_types_data?.length) {
            updatedFilters["recurring_types"] = recurring_types_data.map(
                (item) => item.recurring_type
            );
        }
        if (!!payment_methods_data?.length) {
            updatedFilters["payment_methods"] = payment_methods_data.map(
                (item) => item.payment_method
            );
        }
        if (!!customer_statuses_data?.length) {
            updatedFilters["customer_statuses"] = customer_statuses_data.map(item => item.customer_status);
        }
        handleFilterChange({
            pageNum: 1,
            filters: updatedFilters
        })
    };

    const tableclassName = infoPage ? "table-bg member-info-table pb-4" : "table-bg mosque-unpaid-table pb-4";
    const min_tableclassName = infoPage ? "table-bg member-info-table pb-4" : "min-table-bg mosque-unpaid-small-table pb-4";

    return (
        <>
            <div className={customerList?.length < 3 ? min_tableclassName :tableclassName} style={{height:'auto'}}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    px="20px"
                    py="28px"
                >
                    <Label fontSize="20px" fontWeight={700}>
                        {blockedView ? blockedViewTitle : "Ledeninformatie"}
                    </Label>
                    <Box className="d-flex">
                        <Searchbar
                            value={qtext}
                            setValue={(e) => handleButton(e.target.value)}
                            placeholder={t("Zoeken")}
                            fontSize="12px"
                            border="0px solid rgba(0,0,0,.08)"
                            searchIcon
                            backgroundColor="#F5F5F5"
                            color="#000"
                            borderRadius="10px"
                            width="252px"
                            height="38px"
                            paddingLeft="25px"
                            position="relative"
                            right="20px"
                            inputProps={{
                                type: "search",
                                onKeyPress: handleKeyPress,
                                height: "20px",
                                marginBottom: "3px",
                            }}
                        />
                        {!isFilterBlocked ?
                            <BoxContainer>
                                <CheckboxMenu
                                    options={bizIndustryLinks}
                                    value={filters}
                                    onChange={onCheckboxChange}
                                />
                            </BoxContainer> :
                            null
                        }
                        {/*{infoPage && !!getStatus() && getStatus()?.payment_status === "unpaid" ?
                            <Button
                                type={hasAttributeWithValueOne ? "primary" : "outlineColor"}
                                disabled={is_sending_reminder}
                                onClick={() => sendReminder()}
                                px="25px"
                                minWidth="175px"
                                minHeight="35px"
                                ml="34px">{is_sending_reminder ? 'Sending...' : 'Send Reminder'}</Button>
                            :
                            null
                        }*/}
                    </Box>
                </Box>
                <Table
                    gridTemplateColumnsHeader={"10% 30% 20% 20% 20%"}
                    gridTemplateColumnsBody={"10% 30% 20% 20% 20%"}
                    columns={previewTableData}
                    dataCount={customerList?.length}
                    data={renderBlockedUnpaidMemberList()}
                    isAll={isAllAttributesSetToOne()}
                    onClickAll={() => handleOnClick()}
                    fetching={isFetching}
                />
                {!infoPage && customerList?.length > 0 ? (
                    <Box display="flex" justifyContent="center" style={{marginTop:'20px', marginBottom:"50px"}} >
                        <Button
                            onClick={() => handleRedirection()}
                            type="outlineColor"
                            px="25px"
                        >
                            {t('Bekijk alle leden')}
                        </Button>
                        {/*<Button
                            type={hasAttributeWithValueOne ? "primary" : "outlineColor"}
                            disabled={is_sending_reminder}
                            onClick={() => sendReminder()}
                            px="25px"
                            ml="34px"
                        >
                            {is_sending_reminder ? "Sending..." : "Send Reminder"}
                        </Button>*/}
                    </Box>
                ) : null}
                <DefaultModal
                    show={isMemberModalVisible}
                    //onHide={() => showMemberDetails(null, false)}
                    className="profile-modal"
                    style={{paddingRight: "0px"}}
                    centered={false}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    {/*<ProfileModal onHide={showMemberDetails}/>*/}
                </DefaultModal>
            </div>
        </>
    );
}

export default MemberTable;
